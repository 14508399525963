var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                _c("h2", { staticClass: "elementSubTitle" }, [
                  _vm._v("Export Excel")
                ])
              ]),
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "div",
                  { staticClass: "elementPanel" },
                  [
                    _vm.isLoading
                      ? _c("LoaderCard", {
                          attrs: { flat: "", type: "spinner--center" }
                        })
                      : _vm._e(),
                    !_vm.isLoading
                      ? _c("List", {
                          attrs: { labels: _vm.labels, items: _vm.items },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "datum",
                                fn: function(item) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dateFormat")(item.date)
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "name",
                                fn: function(item) {
                                  return [_vm._v(" " + _vm._s(item.name) + " ")]
                                }
                              },
                              {
                                key: "period",
                                fn: function(item) {
                                  return [
                                    item.hasPeriodFilter
                                      ? _c("PeriodFilter", {
                                          attrs: {
                                            to: _vm.toDate,
                                            from: _vm.fromDate
                                          },
                                          on: { change: _vm.applyDateFilters }
                                        })
                                      : _vm._e()
                                  ]
                                }
                              },
                              {
                                key: "actions",
                                fn: function(item) {
                                  return [
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "cursor-pointer",
                                          on: {
                                            click: function($event) {
                                              return item.action()
                                            }
                                          }
                                        },
                                        [_vm._v("file_download")]
                                      )
                                    ]
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2227416365
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }