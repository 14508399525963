import { Organization } from '@/models/Organization';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError, AxiosResponse } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { Label } from '@/components/List/List';
import { User } from '@/models/User';
import { PermissionSlug } from '@/support/PermissionSlug';
import { Export } from '@/models/Export';
import { downloadExcelRequest, ExcelOptions } from '@/support/Client';
import PeriodFilter from '@/components/filters/period-filter/PeriodFilter.vue';
import { DateTime } from 'luxon';
import { Options } from '@/components/mi-dialog/MiDialog';
import { cloneDeep } from 'lodash';
import { Application } from '@/support/Application';

@Component<ExportExcel>({
  components: {
    PeriodFilter,
  },
})
export default class ExportExcel extends Vue {
  // #region Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  protected fromDate = '';

  protected toDate = '';

  protected isLoading = true;

  protected labels: Label[] = [
    {
      slotName: 'name',
      title: 'Naam',
      width: 'xs6',
    },
    {
      slotName: 'period',
      title: 'Periode',
      width: 'xs4',
    },
    {
      slotName: 'actions',
      width: 'xs2',
      class: 'text-right',
    },
  ];

  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Lifecycle hooks
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  protected mounted(): void {
    this.onMounted();
  }

  // #endregion

  // #region Lifecycle hook handlers

  protected onMounted(): void {
    this.initialize();
  }

  // #endregion

  // #region Helpers
  /**
  * ie.
  * protected toggleLoading(): void {
  *  this.isLoading = ! this.isLoading;
  * }
  */

  protected async initialize(): Promise<void> {
    this.isLoading = true;

    await this.fetchCurrentUser();

    const lastDayOfMonth = DateTime.local().minus({ month: 1 }).daysInMonth;
    this.fromDate = `${DateTime.local().minus({ month: 1 }).toFormat('yyyy-MM')}-01`;
    this.toDate = `${DateTime.local().minus({ month: 1 }).toFormat('yyyy-MM')}-${lastDayOfMonth}`;

    await this.fetchOrganizations();

    this.isLoading = false;
  }

  protected applyDateFilters(from: string, to: string): void {
    this.fromDate = from;
    this.toDate = to;
  }

  protected async handleDownloadPromise(downloadPromise: void | Promise<AxiosResponse>): Promise<void> {
    if (! downloadPromise) { return; }

    const response = await downloadPromise.catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });

    if (response) {
      if (response.status === 201) {
        this.$store.dispatch('openDialog', this.dialogSuccessDownloadRequest);
      } else {
        ErrorHandler.alert();
      }
    }
  }

  // #endregion

  // #region API requests
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async fetchCurrentUser(): Promise<void> {
    await new User(this.$store.state.Auth.uuid)
      .current()
      .then((user: User) => {
        this.$store.dispatch('userAuthenticated', user);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });
  }

  protected async fetchOrganizations(): Promise<void> {
    if (! this.$store.state.isServiceOrganization) {
      this.addOrganizationsToItems([cloneDeep(this.$store.state.Auth.organization)]);
      return;
    }
    const organizations: Organization[] = await new Organization()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });

    if (organizations) {
      this.addOrganizationsToItems(organizations);
    }
  }

  protected addOrganizationsToItems(organizations: Organization[]): void {
    organizations.forEach((organization) => {
      this.items.push({
        name: `Download ${organization.name} excel`,
        hidden: ! this.user.can(PermissionSlug.EXPORTS_REPORTS),
        action: async () => {
          await this.handleDownloadPromise(new Export().downloadExcel(organization.id || ''));
        },
      });
    });
  }

  protected async downloadBiUpdateExcel(): Promise<void> {
    const options: ExcelOptions = {
      url: '/v1/exports/bi-update',
    };

    const payload = new URLSearchParams();
    if (this.fromDate) {
      payload.append('period[from]', this.fromDate);
    }

    if (this.toDate) {
      payload.append('period[to]', this.toDate);
    }

    await this.handleDownloadPromise(downloadExcelRequest(options, payload));
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get user(): User {
    return this.$store.state.Auth;
  }

  protected get items(): Item[] {
    return [
      {
        name: 'BI Update',
        hidden: ! this.user.can(PermissionSlug.EXPORTS_BI_UPDATE),
        hasPeriodFilter: true,
        action: async () => {
          this.downloadBiUpdateExcel();
        },
      },
      {
        name: 'Fact sheet',
        hidden: ! this.user.can(PermissionSlug.EXPORTS_FACT_SHEET),
        action: async () => {
          await this.handleDownloadPromise(new Export().downloadFactSheet());
        },
      },
      {
        name: 'PGV',
        hidden: ! this.user.can(PermissionSlug.EXPORTS_PGV),
        action: async () => {
          await this.handleDownloadPromise(new Export().downloadPgvExport());
        },
      },
      {
        name: 'PerformanceExcel',
        hidden: ! this.user.can(PermissionSlug.EXPORTS_PROFICIENCY_REPORT),
        action: async () => {
          await this.handleDownloadPromise(new Export().downloadPerformanceExcel());
        },
      },
      {
        name: 'ConversionMatrix',
        hidden: ! this.user.can(PermissionSlug.EXPORTS_CONVERSIONMATRIX),
        action: async () => {
          await this.handleDownloadPromise(new Export().downloadConversionMatrix());
        },
      },
      {
        name: 'CED Excel',
        hidden: ! (
          this.user.can(PermissionSlug.EXPORTS_REPORTS)
          && (
            ((Application.config('APP_APPLICATION_MODE') as string) || '') === 'ced'
            || ((Application.config('APP_ENV') as string) || '') === 'staging'
            || ((Application.config('APP_ENV') as string) || '') === 'local'
          )
        ),
        action: async () => {
          await this.handleDownloadPromise(new Export().downloadCedExcel());
        },
      },
    ];
  }

  protected get dialogSuccessDownloadRequest(): Options {
    return {
      title: 'Succesvol toegevoegd aan wachtrij',
      text: 'De download is succesvol toegevoegd aan de wachtrij om gegenereerd te worden. Indien die klaar is zal die te bereiken zijn op de Mijn Exports pagina.',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ga naar Mijn Exports',
          action: () => {
            this.$router.push('/mijn-exports');
          },
        },
        cancel: {
          text: 'Sluiten',
          color: 'text-light',
        },
      },
    };
  }

  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}

// #region Interfaces

interface Item {
  name: string;
  hidden: boolean;
  hasPeriodFilter?: boolean;
  action: () => Promise<void>;
}

// #endregion
